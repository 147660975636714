import React, { useEffect, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import Frame from "../../layouts/Frame";
import HOME_PAGE_QUERY from "../../queries/pages/homePage";
import Title from "../../components/Title";
import MissionStatement from "../../components/MissionStatement";
import Divider from "../../components/Divider";
import ArtistDisplay from "../../components/ArtistDisplay";
import Information from "../../components/Information";
import {
  artistsData,
  homePageData,
  homePageQuery,
} from "../../queries/types/types";
import Navigation from "../../components/Navigation";

const Home = () => {
  const { data, loading } = useQuery<homePageQuery>(HOME_PAGE_QUERY);

  const artistRef = useRef<any>();
  const handleRefClick = () => {
    window.scrollTo(0, artistRef.current.offsetTop - 30);
  };

  let homePageObj!: homePageData;
  let artistsObj!: artistsData;

  if (data?.homePage && data?.artists) {
    homePageObj = data.homePage;
    artistsObj = data.artists;
  }

  useEffect(() => {}, [data]);

  return (
    <Frame
      loading={loading}
      children={{
        main: (
          <>
            <Title homePageObj={homePageObj} />
            <Navigation handleRefClick={handleRefClick} />
            <MissionStatement homePageObj={homePageObj} />
            <Divider responsive={false} />
            <ArtistDisplay artistsObj={artistsObj} artistRef={artistRef} />
            <Divider responsive={false} />
            <Information homePageObj={homePageObj} />
          </>
        ),
      }}
    />
  );
};

export default Home;
