import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useHistory } from "react-router-dom";
import filigree_bk_l from "../public/filigree_bk_l.png";
import filigree_bk_r from "../public/filigree_bk_r.png";
import { Image } from "react-bootstrap";
import { navigationQuery, navigationArtistsData } from "../queries/types/types";
import NAVIGATION_QUERY from "../queries/pages/navigation";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import Divider from "./Divider";

type navigationProps = {
  handleRefClick?: () => void;
};

const Navigation = ({ handleRefClick }: navigationProps) => {
  const { data } = useQuery<navigationQuery>(NAVIGATION_QUERY);

  let navigationData!: navigationArtistsData;

  if (data?.artists) {
    navigationData = data.artists;
  }

  useEffect(() => {}, [data]);

  const page = window.location.href.split("/");
  let home = false;
  let aftercare = false;
  let faq = false;

  switch (page[page.length - 1]) {
    case "":
      home = true;
      break;
    case "aftercare":
      aftercare = true;
      break;
    case "faq":
      faq = true;
      break;
    default:
      break;
  }

  const history = useHistory();

  const artistLinks = navigationData?.map((value, i) => (
    <NavDropdown.Item
      onClick={() => {
        history.push(`/artist/${value.slug}`);
      }}
    >
      {value.name}
    </NavDropdown.Item>
  ));

  return (
    <>
      <Navbar collapseOnSelect expand>
        <Container className="navText">
          <Nav className="text-left justify-content-evenly w-100 h-100">
            <div className="d-flex align-items-center justify-content-center">
              <Image
                src={filigree_bk_l}
                fluid
                style={{
                  maxHeight: "3em",
                }}
              />
            </div>
            {!home ? (
              <Nav.Link
                onClick={() => {
                  history.push("/");
                }}
              >
                Home
              </Nav.Link>
            ) : null}
            {!home ? (
              <NavDropdown title="Artists" id="collasible-nav-dropdown">
                {artistLinks}
              </NavDropdown>
            ) : (
              <Nav.Link
                onClick={() => {
                  if (typeof handleRefClick === "function") {
                    handleRefClick();
                  }
                }}
              >
                Artists
              </Nav.Link>
            )}
            {!aftercare ? (
              <Nav.Link
                onClick={() => {
                  history.push("/aftercare");
                }}
              >
                Aftercare
              </Nav.Link>
            ) : null}
            {!faq ? (
              <Nav.Link
                onClick={() => {
                  history.push("/faq");
                }}
              >
                FAQ
              </Nav.Link>
            ) : null}
            <div className="d-flex align-items-center justify-content-center">
              <Image
                src={filigree_bk_r}
                fluid
                style={{
                  maxHeight: "3em",
                }}
              />
            </div>
          </Nav>
        </Container>
      </Navbar>
      {!home && !faq && !aftercare ? (
        <div className="d-block d-md-none">
          <Divider />
        </div>
      ) : null}
    </>
  );
};

export default Navigation;
