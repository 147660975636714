import { useEffect } from "react";
import { useBreakpoint } from "../hooks/useBreakpoint";
import filigree_bk_bt from "../public/filigree_bk_bt.png";

type dividerProps = {
  height?: number;
  left?: boolean;
  right?: boolean;
  responsive?: boolean;
};

const Divider = ({ height, left, right, responsive = true }: dividerProps) => {
  let point: any = useBreakpoint();
  let position!: string;

  useEffect(() => {}, [point, position]);

  if (!responsive && (left || right)) {
    left
      ? (position = "justify-content-left")
      : (position = "justify-content-right");
  } else if (responsive && (point.xl && !point.lg)) {
    position = "justify-content-left";
  } else {
    position = "justify-content-center";
  }

  return (
    <div className={`d-flex ${position}`}>
      <img
        src={filigree_bk_bt}
        alt="page divider"
        className="img-fluid"
        style={{
          maxHeight: height ? height + "px" : "100px",
          maxWidth: "80%"
        }}
      />
    </div>
  );
};

export default Divider;
