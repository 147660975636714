import profile_placeholder from "../public/profile_placeholder.jpg";
import { Row, Col, Image } from "react-bootstrap";
import { artist } from "../queries/types/types";
import ReactMarkdown from "react-markdown";
import FiligreeHeader from "./FiligreeHeader";

const ArtistHeader = ({ artist }: { artist: artist }) => {
  return (
    <Row>
      <Col className="col-12 col-xl-6 px-5 mb-5 mt-4 mt-xl-5 justify-content-center d-flex">
        <Image
          className="rounded shadow"
          fluid
          style={{
            maxHeight: "414px",
          }}
          src={
            artist.profile_image?.url
              ? artist.profile_image.url
              : profile_placeholder
          }
        />
      </Col>
      <Col className="col-12 col-xl-6 px-5 my-auto">
        <FiligreeHeader main text={artist.name} />
        <hr></hr>
        <ReactMarkdown children={artist.bio} />
      </Col>
      <Col></Col>
    </Row>
  );
};

export default ArtistHeader;
