import axios from 'axios'

const getSessionJwt = async (url) => {
  const { data } = await axios.post(url, {
    query: `mutation {
      login(input: { identifier: "${process.env.REACT_APP_WEBUSER_ID}", password: "${process.env.REACT_APP_WEBUSER_PASSWORD}" }) {
        jwt
      }
    }
  `,
  })

  sessionStorage.setItem('tgtoken', data.data.login.jwt);

  return data.data.login.jwt
}

export default getSessionJwt

