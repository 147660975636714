import gql from "graphql-tag";

const ARTIST_QUERY = gql`
  query Artist($slug: String!) {
    artists(
      where: {
        slug: $slug
      }
    ) {
      name
      slug
      bio
      facebook_link
      instagram_link
      email_link
      profile_image {
        url
      }
      header_image {
        url
      }
      tattoo_images {
        url
      }
    }
  }
`;

export default ARTIST_QUERY
