import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import styles from "./InformationStyles.module.scss";
import FiligreeHeader from "./FiligreeHeader";

const SocialLinks = ({
  facebook,
  instagram,
  email,
}: {
  facebook?: string;
  instagram?: string;
  email?: string;
}) => {
  return (
    <>
      {(facebook || instagram || email) && (
        <>
          <Row className="mt-5 justify-content-center">
            <Col className="text-center">
              <FiligreeHeader text={"Get in touch!"} />
            </Col>
          </Row>
          <Row className="mb-5 justify-content-center">
            {facebook && (
              <FontAwesomeIcon
                id={styles.hoverBoi}
                icon={faFacebook}
                className="mx-4"
                style={{
                  fontSize: "7rem",
                }}
                onClick={() => {
                  window.open(facebook);
                }}
              />
            )}
            {instagram && (
              <FontAwesomeIcon
                id={styles.hoverBoi}
                icon={faInstagram}
                className="mx-4"
                style={{
                  fontSize: "7rem",
                }}
                onClick={() => {
                  window.open(instagram);
                }}
              />
            )}
            {email && (
              <FontAwesomeIcon
                id={styles.hoverBoi}
                icon={faEnvelope}
                className="mx-4"
                style={{
                  fontSize: "7rem",
                }}
                onClick={() => {
                  window.open("mailto:" + email);
                }}
              />
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default SocialLinks;
