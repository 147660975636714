import { Row, Col } from "react-bootstrap";
import ArtistCard from "./ArtistCard";
import { Link } from "react-router-dom";
import { artistsData } from "../queries/types/types";
import FiligreeHeader from "./FiligreeHeader";

type ArtistDisplayProps = {
  artistsObj: artistsData;
  artistRef?: React.MutableRefObject<any>;
};

const ArtistDisplay = ({ artistsObj, artistRef }: ArtistDisplayProps) => {
  let sortedArtists: artistsData;

  artistsObj?.length > 0
    ? (sortedArtists = [...artistsObj].sort((a, b) => a.position - b.position))
    : (sortedArtists = []);

  const responsiveClass =
    sortedArtists.length <= 4
      ? `row-cols-lg-${sortedArtists.length}`
      : "row-cols-lg-3";

  const cards = sortedArtists.map((artist, i) => (
    <Col key={i}>
      <Link to={`/artist/${artist.slug}`} className="text-link">
        <ArtistCard artist={artist} />
      </Link>
    </Col>
  ));

  return (
    <Row className="mt-5" ref={artistRef}>
      <Col className="mx-5">
        <div className="d-none" ref={artistRef}></div>
        <FiligreeHeader text={"Our Artists"} />
      </Col>
      <Row
        className={`g-4 row-cols-1 row-cols-md-2 ${responsiveClass} px-4 px-xs-0 m-0 mb-5 justify-content-center`}
      >
        {cards}
      </Row>
    </Row>
  );
};

export default ArtistDisplay;
