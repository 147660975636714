import { Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { homePageData } from "../queries/types/types";

type MissionStatementProps = {
  homePageObj: homePageData;
};

const MissionStatement = ({ homePageObj }: MissionStatementProps) => {
  return (
    <Row className="mt-5">
      <Col className="mx-5">
        <h2 className="m-0">{homePageObj?.mission_statement}</h2>
        <hr />
        <ReactMarkdown children={homePageObj?.mission_statement_descrip} />
      </Col>
    </Row>
  );
};

export default MissionStatement;
