import gql from "graphql-tag";

const FAQ_PAGE_QUERY = gql`
  query FaqPage {
    faq {
      title
      faq_banner {
        url
      }
    }
    faqData {
      question
      answer
    }
  }
`;

export default FAQ_PAGE_QUERY;
