import gql from "graphql-tag";

const NAVIGATION_QUERY = gql`
  query Navigation {
    artists {
      name
      slug
    }
  }
`;

export default NAVIGATION_QUERY
