import gql from "graphql-tag";

const FOOTER_QUERY = gql`
  query Homepage {
    homePage {
      phone
      email
    }
  }
`;

export default FOOTER_QUERY;
