import CircleLoader from 'react-spinners/CircleLoader'

const LoadingSpinner = () => {
  return (
    <div
      className='w-100 h-100'
    >
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '45%',
          transform: 'translate(-50%, -50%)',
        }}
      >
      <CircleLoader
        size={150}
      />
      </div>
    </div>
  )
}

export default LoadingSpinner