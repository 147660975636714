import { Col, Image, Row } from "react-bootstrap";
import square_logo from "../../public/square_logo.png";
import filigree_bk_bt from "../../public/filigree_bk_bt.png";
import SocialLinks from "../../components/SocialLinks";

const Construction = () => {
  return (
    <>
      <Row
        className="m-0"
        style={{
          height: "100vh",
        }}
      >
        <Col className="col-xl-2 d-xl-flex align-items-center justify-content-center p-0 d-none"></Col>
        <Col className="d-flex align-items-center justify-content-center p-0">
          <Image
            src={square_logo}
            fluid
            style={{
              maxHeight: "100vh",
            }}
          />
        </Col>
        <Col className="col-xl-2 d-xl-flex align-items-center justify-content-center p-0 d-none"></Col>
      </Row>
      <Row className="m-0 mt-5 justify-content-center">
        <Col className="text-center">
          <h1 className="responsiveConstruction">UNDER CONSTRUCTION</h1>
          <Image
            src={filigree_bk_bt}
            fluid
            style={{
              opacity: "0.4",
              maxWidth: "17rem",
            }}
          />
          <h3 className="mt-5">
            We're a little busy here at the moment. Feel free to get in touch
            with us in the meantime!
          </h3>
        </Col>
      </Row>
      <Row className="m-0 justify-content-center">
        <SocialLinks
          facebook={"https://www.facebook.com/TheTattooGalleryAdelaide/"}
          instagram={"https://www.instagram.com/tattoogalleryadelaide/"}
          email={"tattoo@tattoogallery.com.au"}
        />
      </Row>
    </>
  );
};

export default Construction;
