import filigree_bk_header from "../public/filigree_bk_header.png";

const FiligreeHeader = ({ text, main }: { text: string; main?: boolean }) => {
  const styles = {
    display: "inline",
    backgroundImage: `url(${filigree_bk_header})`,
    backgroundSize: "auto 75%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
  };

  return (
    <>
      {main ? (
        <h1 className="pe-3 filigree-header" style={styles}>
          {text}
        </h1>
      ) : (
        <h2 className="filigree-header pe-3" style={styles}>
          {text}
        </h2>
      )}
    </>
  );
};

export default FiligreeHeader;
