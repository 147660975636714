import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import { Helmet } from "react-helmet";
import image from "./public/android-chrome-512x512.png"
import "./styles/App.scss";

function App() {
  return (
    <>
      <Helmet>
        <title>Tattoo Gallery</title>
        <meta name="description" content="Adelaide's original and only TATTOO GALLERY since 1995. Originally located in Rundle Street, now residing at 47-49 Payneham Rd, College Park." />
        <meta property="image" content={image} />
        <meta property="og:title" content="Tattoo Gallery" />
        <meta property="og:description" content="Adelaide's original and only TATTOO GALLERY since 1995. Originally located in Rundle Street, now residing at 47-49 Payneham Rd, College Park." />
        <meta property="og:image" content={image} />
     </Helmet>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
