import React, { useEffect } from "react";
import Frame from "../../layouts/Frame";
import FillBanner from "../../components/FillBanner";
import FaqDisplay from "../../components/FaqDisplay";
import { useQuery } from "@apollo/client";
import {
  faqPageData,
  faqPageQuery,
  questionsData,
} from "../../queries/types/types";
import FAQ_PAGE_QUERY from "../../queries/pages/faqPage";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Col, Row } from "react-bootstrap";
import Divider from "../../components/Divider";

const Faq = () => {
  const { data, loading, error } = useQuery<faqPageQuery>(FAQ_PAGE_QUERY);

  if (error) {
    console.log(error);
  }

  let faqObj!: faqPageData;
  let questionsObj!: questionsData;

  if (data?.faq && data?.faqData) {
    faqObj = data.faq;
    questionsObj = data.faqData;
  }

  useEffect(() => {}, [data]);

  return (
    <Frame
      children={{
        main: loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <FillBanner url={faqObj?.faq_banner.url} />
            <Row className="mt-5">
              <Col className="mx-5 text-center text-xl-start">
                <h1>{faqObj?.title}</h1>
                <Divider left height={127} />
              </Col>
            </Row>
            <Col className="mx-5">
              <FaqDisplay faqs={questionsObj} />
            </Col>
          </>
        ),
      }}
    />
  );
};

export default Faq;
