import gql from "graphql-tag";

const HOME_PAGE_QUERY = gql`
  query Homepage {
    homePage {
      mission_statement
      mission_statement_descrip
      facebook
      instagram
      location
      phone
      email
      hours
      desktop_banner {
        url
      }
      mobile_banner {
        url
      }
    }
    artists {
      name
      slug
      bio
      facebook_link
      instagram_link
      email_link
      position
      profile_image {
        url
      }
      header_image {
        url
      }
      tattoo_images {
        url
      }
    }
  }
`;

export default HOME_PAGE_QUERY
