import placeholder_banner from "../public/placeholder_banner.jpg";
import { Image } from "react-bootstrap";
import Navigation from "./Navigation";

const FillBanner = ({ url }: { url?: string }) => {
  return (
    <>
      <div
        className="mb-4"
        style={{
          overflow: "hidden",
          objectFit: "cover",
          maxHeight: "360px",
        }}
      >
        <div className="d-flex justify-content-center">
          <Image
            src={url ? url : placeholder_banner}
            fluid
            style={{
              filter: "grayscale(100%)",
            }}
          />
        </div>
      </div>
      <Navigation />
    </>
  );
};

export default FillBanner;
