import React, { useEffect } from "react";
import Frame from "../../layouts/Frame";
import { Row, Col } from "react-bootstrap";
import FillBanner from "../../components/FillBanner";
import { useQuery } from "@apollo/client";
import AFTERCARE_PAGE_QUERY from "../../queries/pages/aftercarePage";
import {
  aftercarePageQuery,
  aftercarePageData,
} from "../../queries/types/types";
import ReactMarkdown from "react-markdown";
import Divider from "../../components/Divider";

const Aftercare = () => {
  const { data, loading, error } =
    useQuery<aftercarePageQuery>(AFTERCARE_PAGE_QUERY);

  if (error) {
    console.log(error);
  }

  let aftercareObj!: aftercarePageData;

  if (data?.aftercare) {
    aftercareObj = data.aftercare;
  }

  useEffect(() => {}, [data]);

  return (
    <Frame
      loading={loading}
      children={{
        main: (
          <>
            <FillBanner url={aftercareObj?.aftercare_banner.url} />
            <Row className="mt-5">
              <Col className="mx-5 text-center text-xl-start">
                <h1>{aftercareObj?.title}</h1>
                <Divider left height={74} />
              </Col>
            </Row>
            <Row>
              <Col className="mx-5 mt-5 aftercare-section">
                <ReactMarkdown children={aftercareObj?.content} />
              </Col>
            </Row>
          </>
        ),
      }}
    />
  );
};

export default Aftercare;
