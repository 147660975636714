import { useQuery } from "@apollo/client";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FOOTER_QUERY from "../queries/pages/footer";
import { footerHomePageData, footerQuery } from "../queries/types/types";
import styles from "./InformationStyles.module.scss";

const Footer = () => {
  const { data } = useQuery<footerQuery>(FOOTER_QUERY);

  let footerObj!: footerHomePageData;

  if (data?.homePage) {
    footerObj = data.homePage;
  }

  useEffect(() => {}, [data]);

  return (
    <>
      <Row className="my-4 mx-auto footer-font justify-content-evenly footer-adjust">
        <Col className="col-lg-3 d-none d-lg-block text-center">
          <span>© 2021 Tattoo Gallery </span>
        </Col>
        <Col className="col-lg-8 col-12 text-center">
          <Row className="mb-3">
            <Col className="col-12 col-md-4 mb-2 text-center">
              <div
                id={styles.hoverBoi}
                onClick={() => {
                  window.open(`tel:${footerObj?.phone}`);
                }}
              >
                <FontAwesomeIcon icon={faPhoneAlt} />
                <span> {footerObj?.phone}</span>
              </div>{" "}
            </Col>
            <Col className="col-12 col-md-8 text-center">
              <div
                id={styles.hoverBoi}
                onClick={() => {
                  window.open(`mailto:${footerObj?.email}`);
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} />
                <span> {footerObj?.email}</span>
              </div>{" "}
            </Col>
          </Row>
        </Col>
        <Col className="col-12 d-block d-lg-none text-center">
          <span>© 2021 Tattoo Gallery 1.0.0</span>
        </Col>
        <Col className="col-lg-1 col-4 d-none d-lg-block text-center">
          <span>1.0.0</span>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
