import { Row, Col } from "react-bootstrap";
import { useHistory } from 'react-router-dom'

const NotFound = () => {

  const history = useHistory()

  return (
    <Row
      className="m-0"
      style={{
        height: "100vh",
      }}
    >
      <Col className="p-0">
        <div className="fourohfour">
          <div className="overlay text-center d-flex align-items-center justify-content-center flex-column">
            <h1>404</h1>
            <h2>I'm not lost, I'm exploring</h2>
            <h3
              className='btn btn-dark btn-lg mt-2'
              onClick={() => {
                history.goBack()
              }}
            >Go Back to Civilization</h3>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NotFound;
