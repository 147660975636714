import React, { useEffect } from "react";
import Frame from "../../layouts/Frame";
import { useParams } from "react-router-dom";
import FillBanner from "../../components/FillBanner";
import ArtistHeader from "../../components/ArtistHeader";
import SocialLinks from "../../components/SocialLinks";
import ImgCarousel from "../../components/ImgCarousel";
import { useQuery } from "@apollo/react-hooks";
import ARTIST_QUERY from "../../queries/pages/artistPage";
import { artist, artistQuery } from "../../queries/types/types";
import Divider from "../../components/Divider";

const Artists = () => {
  const { name }: { name: string } = useParams();

  const { data, loading } = useQuery<artistQuery>(ARTIST_QUERY, {
    variables: {
      slug: name,
    },
  });
  let artistObj!: artist;

  if (data?.artists) {
    artistObj = data.artists[0];
  }

  useEffect(() => {}, [data]);

  return (
    <Frame
      loading={loading}
      children={{
        main: (
          <>
            <FillBanner url={artistObj?.header_image.url} />
            <ArtistHeader artist={artistObj} />
            <Divider responsive={false} />
            {artistObj?.tattoo_images && (
              <ImgCarousel imgArray={artistObj?.tattoo_images} />
            )}
            <SocialLinks
              facebook={artistObj?.facebook_link}
              instagram={artistObj?.instagram_link}
              email={artistObj?.email_link}
            />
          </>
        ),
      }}
    />
  );
};

export default Artists;
