import gql from "graphql-tag";

const AFTERCARE_PAGE_QUERY = gql`
  query Aftercare {
    aftercare {
      title
      content
      aftercare_banner {
        url
      }
    }
  }
`
export default AFTERCARE_PAGE_QUERY