import { useBreakpoint } from "../hooks/useBreakpoint";
import { homePageData } from "../queries/types/types";

const Title = ({ homePageObj }: { homePageObj: homePageData }) => {
  const point: any = useBreakpoint();

  return (
    <div className="mb-4 p-3">
      <img
        src={
          point.md
            ? homePageObj?.mobile_banner.url
            : homePageObj?.desktop_banner.url
        }
        alt="Tattoo Gallery Logo"
        className="img-fluid"
      />
    </div>
  );
};

export default Title;
