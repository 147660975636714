import { Row, Col, Carousel } from 'react-bootstrap'

const ImgCarousel = ({
  imgArray
}: {
  imgArray: {
    url: string
  }[]
}) => {
  const carouselItems = imgArray.map((img, i) => (
    <Carousel.Item
      style={{
        height: '60vh'
      }}
      key={i}
    >
      <div
        className='h-100 d-flex'
      >
        <img 
          style={{
            maxHeight: '60vh',
            maxWidth: '100%',
          }}
          className='m-auto py-auto'
          src={`${img.url}`}
          alt={`slide ${i + 1}`}
        />
      </div>
    </Carousel.Item>
  ))

  return (
    <Row className="mt-4">
      <Col
        className='col-12 overflow-hidden'
      >
        <Carousel variant="dark">
          {carouselItems}
        </Carousel>
      </Col>
    </Row>
  )
}

export default ImgCarousel