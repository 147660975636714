import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import getSessionJwt from './getSessionJwt';


const link = new createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});

const authLink = setContext(async (_, {headers}) => {
  let token = sessionStorage.getItem('tgtoken');
  if (!token) { 
    token = await getSessionJwt(`${process.env.REACT_APP_BACKEND_URL}/graphql`)
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link)
});

export default client;