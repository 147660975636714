import { Card } from "react-bootstrap";
import profile_placeholder from "../public/profile_placeholder.jpg";
import { artist } from "../queries/types/types";

type ArtistCardProps = {
  artist: artist;
};

const ArtistCard = ({ artist }: ArtistCardProps) => {
  return (
    <Card>
      <Card.Img
        variant="top"
        src={
          artist.profile_image.url
            ? artist.profile_image.url
            : profile_placeholder
        }
      />
      <Card.Body>
        <Card.Title className="text-center">{artist.name}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default ArtistCard;
