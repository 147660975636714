import { Col, Image, Row, Table } from "react-bootstrap";
import SocialLinks from "./SocialLinks";
import filigree_bk_line from "../public/filigree_bk_line.png";

import { homePageData } from "../queries/types/types";

type InformationProps = {
  homePageObj: homePageData;
};

const Information = ({ homePageObj }: InformationProps) => {
  return (
    <div className="my-5 mx-4">
      <Row className="justify-content-evenly align-items-center">
        <Col className="col-12 col-xl-6">
          <Row
            className="justify-content-evenly mb-5 mb-lg-0"
            style={{
              height: "350px",
            }}
          >
            <Col className="col-1 col-md-2 d-flex justify-content-center align-items-center p-0">
              <Image
                src={filigree_bk_line}
                fluid
                style={{
                  maxHeight: "350px",
                }}
              />
            </Col>
            <Col className="col-8 col-md-6">
              <Table hover className="m-0 h-100">
                <tbody
                  style={{
                    marginLeft: "50%",
                    lineHeight: "0.3",
                  }}
                >
                  <tr>
                    <td>{homePageObj?.hours.Monday.title}</td>
                    <td className="text-end">
                      {homePageObj?.hours.Monday.hours}
                    </td>
                  </tr>
                  <tr>
                    <td>{homePageObj?.hours.Tuesday.title}</td>
                    <td className="text-end">
                      {homePageObj?.hours.Tuesday.hours}
                    </td>
                  </tr>
                  <tr>
                    <td>{homePageObj?.hours.Wednesday.title}</td>
                    <td className="text-end">
                      {homePageObj?.hours.Wednesday.hours}
                    </td>
                  </tr>
                  <tr>
                    <td>{homePageObj?.hours.Thursday.title}</td>
                    <td className="text-end">
                      {homePageObj?.hours.Thursday.hours}
                    </td>
                  </tr>
                  <tr>
                    <td>{homePageObj?.hours.Friday.title}</td>
                    <td className="text-end">
                      {homePageObj?.hours.Friday.hours}
                    </td>
                  </tr>
                  <tr>
                    <td>{homePageObj?.hours.Saturday.title}</td>
                    <td className="text-end">
                      {homePageObj?.hours.Saturday.hours}
                    </td>
                  </tr>
                  <tr>
                    <td>{homePageObj?.hours.Sunday.title}</td>
                    <td className="text-end">
                      {homePageObj?.hours.Sunday.hours}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="col-1 col-md-2 d-flex justify-content-center align-items-center p-0">
              <Image
                src={filigree_bk_line}
                fluid
                style={{
                  maxHeight: "350px",
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-xl-6">
          <iframe
            title="google maps location"
            width="100%"
            height="350"
            style={{
              border: 0,
            }}
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=Tattoo+Gallery+Adelaide`}
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
      <Row className='my-5'></Row>
      <SocialLinks
        facebook={homePageObj?.facebook}
        instagram={homePageObj?.instagram}
        email={homePageObj?.email}
      />
    </div>
  );
};

export default Information;
