import { Accordion } from 'react-bootstrap'
import { question } from "../queries/types/types";

const FaqAccordion = ({
  faq,
  index
}: {
  faq: question
  index: number
}) => {
  return (
    <Accordion.Item
      eventKey={index.toString()}
      className="my-5 border rounded shadow-sm"
    >
      <Accordion.Header
      ><h2>{faq.question}</h2></Accordion.Header>
      <Accordion.Body>
        <p>{faq.answer}</p>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default FaqAccordion