import { Route, Switch } from "react-router-dom";
import Aftercare from "../pages/public/Aftercare";
import Artists from "../pages/public/Artists";
import Construction from "../pages/public/Construction";
import Faq from "../pages/public/Faq";
import Home from "../pages/public/Home";
import NotFound from "../pages/public/NotFound";

const Routes = () => {
  return (
    <>
      {process.env.REACT_APP_UNDER_CONSTRUCTION ? (
        <Switch>
          <Route exact path="/" component={Construction} />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/aftercare" component={Aftercare} />
          <Route exact path="/artist/:name" component={Artists} />
          <Route component={NotFound} />
        </Switch>
      )}
    </>
  );
};

export default Routes;
