import { Accordion, Row } from "react-bootstrap";
import { questionsData } from "../queries/types/types";
import FaqAccordion from "./FaqAccordion";

const FaqDisplay = ({ faqs }: { faqs: questionsData }) => {
  const faqCards = faqs?.map((faq, i) => (
      <FaqAccordion faq={faq} index={i} />
  ));
  return (
      <Row>
        <Accordion defaultActiveKey='0' >
          {faqCards}
        </Accordion>
      </Row>
  );
};

export default FaqDisplay;
